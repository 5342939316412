import { getStyleOverrides } from '@/utils/getStyleOverrides';
import { ExpandMore } from '@mui/icons-material';
import { ThemeOptions } from '@mui/material/styles';

export const Select: ThemeOptions = {
	components: {
		MuiSelect: {
			styleOverrides: {
				select: getStyleOverrides({
					styles: {
						padding: '10px 32px 10px 6px',
						fontSize: '12px',
						'~ .MuiSvgIcon-root': {
							color: '#000000',
							right: '4px',
						},
					},
				}),
			},
			defaultProps: {
				IconComponent: ExpandMore,
				MenuProps: {
					PaperProps: {
						sx: {
							borderRadius: 0,
							padding: 0,
							boxShadow: 'none',

							'.MuiList-root': {
								padding: 0,
								border: '1px solid #898989',
							},

							'.MuiMenuItem-root': {
								borderRadius: 0,

								'+ .MuiMenuItem-root': {
									borderTop: '1px solid #898989',
								},

								'&.Mui-selected': {
									color: '#ffffff',
									backgroundColor: '#898989',
								},
							},
						},
					},
				},
			},
		},
	},
};
