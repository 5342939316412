import { ProgressIndicator } from '@/components/blocks/ProgressIndicator';
import { useGlobalSpinner } from '@/data/Content/GlobalSpinner';
import { Backdrop } from '@mui/material';
import { FC } from 'react';

/* Displays a spinner over the entire page */

export const GlobalSpinner: FC = () => {
	const { isLoading } = useGlobalSpinner();

	return (
		<Backdrop open={isLoading} sx={{ zIndex: '999' }}>
			<ProgressIndicator />
		</Backdrop>
	);
};
