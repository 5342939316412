/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { WacoalExpandIcon } from '@/styles/Wacoal/Accordion/WacoalExpandIcon';
import { breakpoints } from '@/styles/Wacoal/breakpoints';
import { getStyleOverrides } from '@/utils/getStyleOverrides';
import { ThemeOptions } from '@mui/material/styles';

export const Accordion: ThemeOptions = {
	components: {
		MuiAccordionSummary: {
			defaultProps: {
				expandIcon: WacoalExpandIcon,
			},
			styleOverrides: {
				root: getStyleOverrides({
					styles: {
						paddingRight: 0,
						paddingLeft: 0,
						minHeight: '58px',
						'&.Mui-expanded': {
							minHeight: '58px',
						},
						'.MuiAccordionSummary-content.Mui-expanded': {
							marginTop: 0,
							marginBottom: 0,
						},
						'.MuiAccordionSummary-expandIconWrapper': {
							color: '#000000',
						},
						'.MuiPaper-mobileOnlyAccordion &': {
							minHeight: 'auto',
							'.MuiAccordionSummary-content': {
								marginTop: 0,
								marginBottom: 0,
							},
							'.MuiAccordionSummary-expandIconWrapper': {
								display: 'none',
							},
						},
					},
				}),
			},
		},
		MuiAccordion: {
			defaultProps: {
				elevation: 0,
			},
			styleOverrides: {
				root: getStyleOverrides({
					styles: {
						borderRadius: '0 !important',
						borderTop: '1px solid #e3e1e1',
						borderBottom: '1px solid #e3e1e1',
						'.MuiAccordion-root + &': {
							borderTop: 0,
						},
						'&.Mui-expanded': {
							margin: '0',
						},
					},
				}),
			},
			variants: [
				{
					props: { variant: 'mobileOnlyAccordion' },
					style: {
						borderBottom: 0,
						borderTop: 0,
						[`@media (min-width:${breakpoints.breakpoints?.values?.md}px)`]: {
							'.MuiCollapse-root': {
								height: 'auto !important',
								visibility: 'visible',
							},
							'.MuiAccordionSummary-root': {
								'.Mui-expanded .expand-less': {
									display: 'none',
								},
								'.expand-more': {
									display: 'none',
								},
								'&:hover:not(.Mui-disabled)': {
									cursor: 'auto',
								},
							},
						},
					},
				},
			],
		},
	},
};
