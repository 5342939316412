import { ThemeOptions } from '@mui/material/styles';

export const Dialog: ThemeOptions = {
	components: {
		MuiDialog: {
			styleOverrides: {
				paper: () => ({
					borderRadius: 0,
					border: '3px solid #a3a3a3',
				}),
			},
		},
	},
};
