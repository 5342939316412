import { Add, Remove } from '@mui/icons-material';
import { Box } from '@mui/material';

export const WacoalExpandIcon = (
	<Box sx={{ '.MuiSvgIcon-root': { display: 'block' } }}>
		<Box sx={{ display: 'none', '.Mui-expanded &': { display: 'block' } }}>
			<Remove />
		</Box>
		<Box sx={{ '.Mui-expanded &': { display: 'none' } }}>
			<Add />
		</Box>
	</Box>
);
