/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

export const DATA_KEY_PAYMENT_INFO = 'PAYMENT_INFO';
export const DATA_KEY_SHIPPING_INFO = 'USABLE_SHIPPING_INFO';
export const DATA_KEY_PERSON = 'person';
export const DATA_KEY_ORDER_BY_ID = 'order-by-id';
export const DATA_KEY_ORDERS_BY_STATUS = 'orders-by-status';
export const DATA_KEY_WISH_LIST = 'wish-list';
export const DATA_KEY_CHECKOUT_PROFILES = 'CHECKOUT_PROFILES';
export const DATA_KEY_CONTRACT = 'contract';
export const DATA_KEY_ORGANIZATION_DESCRIPTIONS = 'organization-descriptions';
export const DATA_KEY_USER = 'User';
export const DATA_KEY_CART = 'cart';
export const DATA_KEY_REQUISITION_LIST = 'RequisitionList';
export const DATA_KEY_ORGANIZATION_DETAILS = 'organization-details';
export const DATA_KEY_VIEW_UPLOAD_LOG = 'ViewUploadLog';
