/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { ID } from '@/data/types/Basic';
import dynamic from 'next/dynamic';
import { ComponentType } from 'react';

export const contentManifestCustom: Record<string, ComponentType<{ id: ID }>> = {
	Header: dynamic(() => import('@/components/content/Header').then((mod) => mod.Header)),
	Footer: dynamic(() => import('@/components/content/Footer').then((mod) => mod.Footer)),
	Error404: dynamic(() => import('@/components/content/Error404').then((mod) => mod.Error404)),
	WacoalCarouselCatalogEntryRecommendation: dynamic(() =>
		import('@/components/content/WacoalCarouselCatalogEntryRecommendation').then(
			(mod) => mod.WacoalCarouselCatalogEntryRecommendation
		)
	),
	WacoalMerchandisingAssociation: dynamic(() =>
		import('@/components/content/WacoalMerchandisingAssociation').then(
			(mod) => mod.WacoalMerchandisingAssociation
		)
	),
	ProductDetailsUpsell: dynamic(() =>
		import(
			'@/components/content/WacoalMerchandisingAssociation/UpsellMerchandisingAssociation'
		).then((mod) => mod.WacoalUpsellMerchandisingAssociation)
	),
	WacoalXsellMerchandisingAssociation: dynamic(() =>
		import(
			'@/components/content/WacoalMerchandisingAssociation/XsellMerchandisingAssociation'
		).then((mod) => mod.WacoalXsellMerchandisingAssociation)
	),
	ProductDetailsScene7Viewer: dynamic(() =>
		import('@/components/content/WacoalProductPage/Scene7Viewer').then(
			(mod) => mod.ProductDetailsScene7Viewer
		)
	),
	ProductDetailsNameAndPrice: dynamic(() =>
		import('@/components/content/WacoalProductPage/NameAndPrice').then(
			(mod) => mod.ProductDetailsNameAndPrice
		)
	),
	ProductDetailsDefiningAttributes: dynamic(() =>
		import('@/components/content/WacoalProductPage/DefiningAttributes').then(
			(mod) => mod.ProductDetailsDefiningAttributes
		)
	),
	ProductDetailsShopperActions: dynamic(() =>
		import('@/components/content/WacoalProductPage/ShopperActions').then(
			(mod) => mod.ProductDetailsShopperActions
		)
	),
	ProductDetailsShortDescription: dynamic(() =>
		import('@/components/content/WacoalProductPage/ShortDescription').then(
			(mod) => mod.ProductDetailsShortDescription
		)
	),
	ProductDetailsLongDescription: dynamic(() =>
		import('@/components/content/WacoalProductPage/LongDescription').then(
			(mod) => mod.ProductDetailsLongDescription
		)
	),
	ProductDetailsDescriptiveAttribute: dynamic(() =>
		import('@/components/content/WacoalProductPage/DescriptiveAttribute').then(
			(mod) => mod.ProductDetailsDescriptiveAttribute
		)
	),
	ProductDetailsBazaarVoiceReviews: dynamic(() =>
		import('@/components/content/WacoalProductPage/BazaarVoiceReviews').then(
			(mod) => mod.BazaarVoiceReviews
		)
	),
	ProductDetailsAddToBagForFreeShippingEspot: dynamic(
		() =>
			import('@/components/content/WacoalProductPage/AddToBagForFreeShippingEspot').then(
				(mod) => mod.AddToBagForFreeShippingEspot
			),
		{
			loading: undefined,
		}
	),
	ProductDetailsAccordion: dynamic(
		() =>
			import('@/components/content/WacoalProductPage/DetailsAccordion').then(
				(mod) => mod.ProductDetailsAccordion
			),
		{
			loading: undefined,
		}
	),
	ProductDetailsFitTipsAccordion: dynamic(() =>
		import('@/components/content/WacoalProductPage/FitTipsAccordion').then(
			(mod) => mod.ProductDetailsFitTipsAccordion
		)
	),
	ProductDetailsXSell: dynamic(() =>
		import('@/components/content/WacoalProductPage/XSell').then(
			(mod) => mod.WacoalProductDetailsXSell
		)
	),
	ProductNotFound: dynamic(() =>
		import('@/components/content/ProductNotFound').then((mod) => mod.ProductNotFound)
	),
	CustomEspotWithPopup: dynamic(() =>
		import('@/components/content/ContentRecommendation/EspotWithPopup').then(
			(mod) => mod.EspotWithPopup
		)
	),
};
