import { SxProps, Theme } from '@mui/material';

export const productDetailLeftSX: SxProps<Theme> = () => ({
	width: { xs: '100%', md: '58.333333%' },
	position: { md: 'sticky' },
	top: { md: '120px' },
	float: { md: 'left' },
	'& .displayName': {
		display: {
			md: 'none',
		},
	},
});
