import { ProductType } from '@/data/types/Product';

export const trackPageView = () => {
	if (false === detectBots()) {
		window?.analytics?.page();
	}
};

export const setSegmentData = (eventName: string, payload: ProductType) => {
	if (false === detectBots()) {
		window?.analytics?.track(eventName, payload);
	}
};

export const detectBots = () => {
	let isBot = false;
	const userAgent = window?.navigator?.userAgent;

	const robots = [
		'APIs-Google',
		'AdsBot',
		'Googlebot',
		'mediapartners',
		'Google Favicon',
		'FeedFetcher',
		'DuplexWeb-Google',
		'googleweblight',
		'Google-Safety',
		'yandex',
		'baidu',
		'duckduck',
		'ecosia',
		'ia_archiver',
		'facebook',
		'instagram',
		'pinterest',
		'reddit',
		'slack',
		'twitter',
		'whatsapp',
		'youtube',
		'semrush',
		'AmazonProductDiscoverybot',
		'spider',
		'crawl',
		'bot',
	];

	if (userAgent) {
		robots.forEach((robotName) => {
			if (!isBot && userAgent.includes(robotName)) {
				isBot = true;
			}
		});
	}

	return isBot;
};
