import { LayoutSlot } from '@/components/layouts/LayoutSlot';
import { ContentItem } from '@/data/types/LayoutAllSlots';
import { Accordion, AccordionDetails, AccordionSummary, Stack } from '@mui/material';
import { isEmpty } from 'lodash';
import { FC } from 'react';

/* Display the Accordion Summary and Details if the content for both exists */

export const AccordionSlotsDisplay: FC<{
	accordionTitle: ContentItem[] | undefined;
	accordionContent: ContentItem[] | undefined;
	defaultExpanded?: boolean;
}> = ({ accordionTitle, accordionContent, defaultExpanded = false }) =>
	!isEmpty(accordionTitle) && !isEmpty(accordionContent) ? (
		<Accordion defaultExpanded={defaultExpanded}>
			<AccordionSummary>
				<LayoutSlot slot={accordionTitle} />
			</AccordionSummary>
			<AccordionDetails
				sx={{
					fontFamily: 'ProximaNova',
					fontSize: '15px',
					lineHeight: '20px',
					letterSpacing: '0.25px',
				}}
			>
				<Stack spacing={2}>
					<LayoutSlot slot={accordionContent} />
				</Stack>
			</AccordionDetails>
		</Accordion>
	) : null;
