import { ItemWrap } from '@/components/layouts/ItemWrap';
import { LayoutSlot } from '@/components/layouts/LayoutSlot';
import { Layout } from '@/data/types/Layout';
import { Stack, useTheme } from '@mui/material';
import { FC } from 'react';

export const HomepageLayout: FC<{ layout: Layout }> = ({ layout }) => {
	const {
		dimensions: { contentSpacing },
	} = useTheme();
	return layout ? (
		<>
			<LayoutSlot slot={layout.slots.header} />
			<Stack component="main" sx={{ minHeight: '1000px' }} gap={contentSpacing} alignItems="center">
				<LayoutSlot slot={layout.slots.first} />
				<LayoutSlot slot={layout.slots.second} ItemWrap={ItemWrap} />
				<LayoutSlot slot={layout.slots.footer} />
			</Stack>
		</>
	) : null;
};
