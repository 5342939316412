/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { HomepageLayout } from '@/components/layouts/HomepageLayout';
import { WacoalProductPage } from '@/components/layouts/WacoalProductPage';
import { Layout } from '@/data/types/Layout';
import { FC } from 'react';

export const layoutManifestCustom: {
	[key: string]: FC<{ layout: Layout }>;
} = { HomepageLayout, WacoalProductPage };
