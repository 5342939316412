/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { Accordion } from '@/styles/Wacoal/Accordion';
import { Alert } from '@/styles/Wacoal/Alert';
import { Button } from '@/styles/Wacoal/Button';
import { Checkbox } from '@/styles/Wacoal/Checkbox';
import { Container } from '@/styles/Wacoal/Container';
import { CssBaseline } from '@/styles/Wacoal/CssBaseline';
import { Dialog } from '@/styles/Wacoal/Dialog';
import { Icon } from '@/styles/Wacoal/Icon';
import { Link } from '@/styles/Wacoal/Link';
import { Radio } from '@/styles/Wacoal/Radio';
import { Select } from '@/styles/Wacoal/Select';
import { TextField } from '@/styles/Wacoal/TextField';
import { breakpoints } from '@/styles/Wacoal/breakpoints';
import { dimensions } from '@/styles/Wacoal/dimensions';
import { fonts } from '@/styles/Wacoal/fonts';
import { palette } from '@/styles/Wacoal/palette';
import { shape } from '@/styles/Wacoal/shape';
import { typography } from '@/styles/Wacoal/type';
import { ThemeManifestTheme } from '@/styles/manifest';

export const WacoalTheme: ThemeManifestTheme = {
	inheritFrom: 'Default',
	components: [
		palette,
		typography,
		shape,
		dimensions,
		breakpoints,
		TextField,
		Button,
		fonts,
		Accordion,
		CssBaseline,
		Container,
		Checkbox,
		Icon,
		Link,
		Radio,
		Dialog,
		Select,
		Alert,
	],
};
