/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { Settings } from '@/data/Settings';
import { User } from '@/data/User';
import { getProductNotFoundPage } from '@/data/containers/ProductNotFoundPage';
import { RouteProtection } from '@/data/containers/manifest';
import { IncomingContent } from '@/data/types/IncomingContent';
import { Layout } from '@/data/types/Layout';
import { Order } from '@/data/types/Order';
import { validateProtectedRoute } from '@/data/utils/validateProtectedRoute';
import { TranslationTable } from 'integration/generated/translations';

const layoutManifest = { ProductNotFoundPage: getProductNotFoundPage };

export const dataContainerManifestCustom: {
	[key: string]: (props: IncomingContent) => Layout;
} = layoutManifest;

type LayoutKeys = keyof typeof layoutManifest;
type LocalRoutes = TranslationTable['Routes'];

/**
 * Layout relationships with translation route name as the key and
 * the layoutManifest key as the value.
 */
export const dataRouteManifestCustom: Record<string, LayoutKeys> = {
	ProductNotFound: 'ProductNotFoundPage',
};

/**
 * Map of routes that are dependent on the user status for conditional
 * redirection or other protection. The method return dictates the
 * behavior:
 * 	- true: allow access
 * 	- false: do not load the route
 * 	- string: redirect to the route named in the string
 */
export const dataRouteProtectionCustom: Partial<
	Record<
		keyof LocalRoutes,
		(user: Partial<User>, cart?: Order, settings?: Settings) => RouteProtection
	>
> = {
	Account: (user, _cart, settings) => validateProtectedRoute({ user, settings }, 'login'),
	AddressBook: (user, _cart, settings) => validateProtectedRoute({ user, settings }, 'login'),
	WishLists: (user, _cart, settings) => validateProtectedRoute({ user, settings }, 'login'),
	CheckoutProfiles: (user, _cart, settings) => validateProtectedRoute({ user, settings }, 'login'),
	OrderHistory: (user, _cart, settings) => validateProtectedRoute({ user, settings }, 'login'),
	OrderDetails: (user, _cart, settings) => validateProtectedRoute({ user, settings }, 'login'),
};
