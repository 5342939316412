import { ThemeOptions } from '@mui/material/styles';

/* Relative path to upload folder will not work in development */
const path =
	process.env.NODE_ENV === 'production'
		? '/wcsstore/WacoalStorefrontAssetStore/upload/css/font/'
		: '/fonts/';

export const fonts: ThemeOptions = {
	fonts: [
		{
			family: 'NeutraTextAlt',
			style: 'normal',
			display: 'swap',
			weight: 300,
			src: `url(${path}NeutraText%20LightAlt.otf) format("opentype")`,
			// src: `url(/Btemptd/fonts/NeutraText%20LightAlt.otf) format("opentype")`,
		},
		{
			family: 'NeutraTextAlt',
			style: 'normal',
			display: 'swap',
			weight: 400,
			src: `url(${path}NeutraText%20BookAlt.otf) format("opentype")`,
			// src: `url(/Btemptd/fonts/NeutraText%20BookAlt.otf) format("opentype")`,
		},
		{
			family: 'NeutraTextAlt',
			style: 'italic',
			display: 'swap',
			weight: 400,
			src: `url(${path}NeutraText%20Book%20Italic%20Alt.otf) format("opentype")`,
			// src: `url(/Btemptd/fonts/NeutraText%20Book%20Italic%20Alt.otf) format("opentype")`,
		},
		{
			family: 'NeutraTextAlt',
			style: 'normal',
			display: 'swap',
			weight: 600,
			src: `url(${path}NeutraText%20DemiAlt.otf) format("opentype")`,
			// src: `url(/Btemptd/fonts/NeutraText%20DemiAlt.otf) format("opentype")`,
		},
		{
			family: 'NeutraTextAlt',
			style: 'italic',
			display: 'swap',
			weight: 600,
			src: `url(${path}NeutraText%20Demi%20Italic%20Alt.otf) format("opentype")`,
			// src: `url(/Btemptd/fonts/NeutraText%20Demi%20Italic%20Alt.otf) format("opentype")`,
		},
		{
			family: 'NeutraTextAlt',
			style: 'normal',
			display: 'swap',
			weight: 700,
			src: `url(${path}NeutraText%20BoldAlt.otf) format("opentype")`,
			// src: `url(/Btemptd/fonts/NeutraText%20BoldAlt.otf) format("opentype")`,
		},
		{
			family: 'NeutraTextAlt',
			style: 'italic',
			display: 'swap',
			weight: 700,
			src: `url(${path}NeutraTextTF%20BoldItalicAlt.otf) format("opentype")`,
			// src: `url(/Btemptd/fonts/NeutraTextTF%20BoldItalicAlt.otf) format("opentype")`,
		},

		{
			family: 'ProximaNova',
			style: 'normal',
			display: 'swap',
			weight: 300,
			src: `url(${path}ProximaNova-Light-webfont.ttf) format("truetype"),
						url(${path}ProximaNova-Light-webfont.woff) format("woff")`,
			// src: `url(/Wacoal/fonts/ProximaNova-Light-webfont.ttf) format("truetype"),
			//			url(/Wacoal/fonts/ProximaNova-Light-webfont.woff) format("woff")`,
		},
		{
			family: 'ProximaNova',
			style: 'normal',
			display: 'swap',
			weight: 400,
			src: `url(${path}ProximaNova-Reg-webfont.ttf) format("truetype"),
						url(${path}ProximaNova-Reg-webfont.woff) format("woff")`,
			// src: `url(/Wacoal/fonts/ProximaNova-Reg-webfont.ttf) format("truetype"),
			//			url(/Wacoal/fonts/ProximaNova-Reg-webfont.woff) format("woff")`,
		},
		{
			family: 'ProximaNova',
			style: 'normal',
			display: 'swap',
			weight: 600,
			src: `url(${path}ProximaNova-Sbold-webfont.ttf) format("truetype"),
						url(${path}ProximaNova-Sbold-webfont.woff) format("woff")`,
			// src: `url(/Wacoal/fonts/ProximaNova-Sbold-webfont.ttf) format("truetype"),
			//			url(/Wacoal/fonts/ProximaNova-Sbold-webfont.woff) format("woff")`,
		},
		{
			family: 'ProximaNova',
			style: 'normal',
			display: 'swap',
			weight: 700,
			src: `url(${path}ProximaNova-Bold-webfont.ttf) format("truetype"),
						url(${path}ProximaNova-Bold-webfont.woff) format("woff")`,
			// src: `url(/Wacoal/fonts/ProximaNova-Bold-webfont.ttf) format("truetype"),
			//			url(/Wacoal/fonts/ProximaNova-Bold-webfont.woff) format("woff")`,
		},
		{
			family: 'ProximaNova',
			style: 'normal',
			display: 'swap',
			weight: 800,
			src: `url(${path}ProximaNova-Xbold-webfont.ttf) format("truetype"),
						url(${path}ProximaNova-Xbold-webfont.woff) format("woff")`,
			// src: `url(/Wacoal/fonts/ProximaNova-Xbold-webfont.ttf) format("truetype"),
			//			url(/Wacoal/fonts/ProximaNova-Xbold-webfont.woff) format("woff")`,
		},

		{
			family: 'Roboto',
			style: 'normal',
			display: 'swap',
			weight: 300,
			src: `url(${path}Roboto-Light.ttf) format("truetype")`,
			// src: `url(/fonts/Roboto-Light.ttf) format("truetype")`,
			unicodeRange: 'U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F',
		},
		{
			family: 'Roboto',
			style: 'normal',
			display: 'swap',
			weight: 400,
			src: `url(${path}Roboto-Regular.ttf) format("truetype")`,
			// src: `url(/fonts/Roboto-Regular.ttf) format("truetype")`,
			unicodeRange: 'U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F',
		},
		{
			family: 'Roboto',
			style: 'normal',
			display: 'swap',
			weight: 500,
			src: `url(${path}Roboto-Medium.ttf) format("truetype")`,
			// src: `url(/fonts/Roboto-Medium.ttf) format("truetype")`,
			unicodeRange: 'U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F',
		},
		{
			family: 'Roboto',
			style: 'normal',
			display: 'swap',
			weight: 700,
			src: `url(${path}Roboto-Bold.ttf) format("truetype")`,
			// src: `url(/fonts/Roboto-Bold.ttf) format("truetype")`,
			unicodeRange: 'U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F',
		},
		{
			family: 'Roboto',
			style: 'normal',
			display: 'swap',
			weight: 800,
			src: `url(${path}Roboto-Black.ttf) format("truetype")`,
			unicodeRange: 'U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F',
		},
		{
			family: 'NewYork',
			style: 'normal',
			display: 'swap',
			weight: 400,
			src: `url(${path}NewYork.otf) format("OpenType")`,
			unicodeRange: 'U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F',
		},
	],
};
