import { ThemeOptions } from '@mui/material/styles';

export const dimensions: ThemeOptions = {
	dimensions: {
		header: {
			height: {
				desktop: 88,
				mobile: 64,
			},
		},
		menu: {
			height: {
				desktop: 40,
			},
		},
		searchBar: {
			width: 340,
			height: 40,
		},
		productCard: {
			swatch: 20,
			thumbnail: 140,
		},
		pagination: {
			button: 40,
		},
		inputFields: {
			height: 45,
		},
		contentSpacing: {
			xs: 0,
			sm: 0,
			md: 0,
			lg: 0,
		},
	},
};
