/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { getPageDataFromId, usePageDataFromId } from '@/data/_PageDataFromId';
import { DEFAULT_META } from '@/data/config/DEFAULTS';
import {
	BTEMPTD_STORE_NAME_DISPLAY,
	BTEMPTD_STORE_TOKEN,
	WACOAL_CANADA_STORE_NAME,
	WACOAL_CANADA_STORE_TOKEN,
} from '@/data/constants/store';
import { Cache } from '@/data/types/Cache';
import { GetServerSidePropsContext } from 'next';
import { ParsedUrlQuery } from 'querystring';
import { useMemo } from 'react';

export type Meta = {
	title: string;
	description: string;
	keywords: string;
};

const dataMap = (contents: any): Meta =>
	[contents].reduce(
		(meta, item) => ({
			...meta,
			title: item?.page?.title || meta.title,
			description: item?.page?.metaDescription || meta.description,
			keywords: item?.page?.metaKeyword || meta.keywords,
		}),
		DEFAULT_META
	);

/**
 * No new data is needed, so just pass through data in case no other method has
 * loaded it yet in the server cache.
 */
export const getMeta = async (
	cache: Cache,
	path: ParsedUrlQuery['path'],
	context: GetServerSidePropsContext
) => await getPageDataFromId(cache, path, context);

export const useMeta = () => {
	const { data, error, loading, storeToken } = usePageDataFromId();

	const defaultMeta = useMemo(() => {
		if (storeToken?.urlKeywordName) {
			if (storeToken.urlKeywordName === WACOAL_CANADA_STORE_TOKEN) {
				return {
					title: WACOAL_CANADA_STORE_NAME,
					description: WACOAL_CANADA_STORE_NAME,
					keywords: '',
				};
			} else if (storeToken.urlKeywordName === BTEMPTD_STORE_TOKEN) {
				return {
					title: BTEMPTD_STORE_NAME_DISPLAY,
					description: BTEMPTD_STORE_NAME_DISPLAY,
					keywords: '',
				};
			} else {
				return DEFAULT_META;
			}
		} else {
			return DEFAULT_META;
		}
	}, [storeToken]);

	return {
		meta: data ? dataMap(data) : defaultMeta,
		loading,
		error,
	};
};
