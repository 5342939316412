
import { HttpClient } from './http-client';
import { SiteContentResource } from './SiteContentResource';
import { V2ProductResource } from './V2ProductResource';
import { ProductViewResource } from './ProductViewResource';
const publicClient = new HttpClient({
	baseUrl: process.env.NODE_ENV === 'production' ? '/search/resources':'/api/search',
});
const privateClient = new HttpClient({
	baseUrl: (process.env.USE_MOCK === 'true' ? 'http://localhost:' + process.env.MOCK_HOST_PORT : process.env.SEARCH_ORIGIN as string) + '/search/resources',
});

export const customQuerySiteContentResource = (pub: boolean) => new SiteContentResource(pub ? publicClient : privateClient);
export const customQueryV2ProductResource = (pub: boolean) => new V2ProductResource(pub ? publicClient : privateClient);
export const customQueryProductViewResource = (pub: boolean) => new ProductViewResource(pub ? publicClient : privateClient);
