import { SxProps, Theme } from '@mui/material';

export const productDetailRightSX: SxProps<Theme> = () => ({
	width: { xs: '100%', md: '41.666667%' },
	float: { md: 'right' },
	'& .displayName': {
		display: {
			md: 'block',
			xs: 'none',
		},
	},
});
