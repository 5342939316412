/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { IncomingContent } from '@/data/types/IncomingContent';
import { Layout } from '@/data/types/Layout';
import { getContentItemForSlot } from '@/data/utils/getContentItemForSlot';

export const getProductPage = (props: IncomingContent): Layout => ({
	name: 'WacoalProductPage',
	slots: {
		header: [{ name: 'Header', id: 'header' }],
		first: getContentItemForSlot(props, 'first'),
		second: getContentItemForSlot(props, 'second'),
		third: getContentItemForSlot(props, 'third'),
		accordionTitle1: getContentItemForSlot(props, 'fourth'),
		accordionContent1: getContentItemForSlot(props, 'fifth'),
		accordionTitle2: getContentItemForSlot(props, 'sixth'),
		accordionContent2: getContentItemForSlot(props, 'seventh'),
		accordionTitle3: getContentItemForSlot(props, 'eighth'),
		accordionContent3: getContentItemForSlot(props, 'ninth'),
		fourth: getContentItemForSlot(props, 'tenth'),
		fifth: getContentItemForSlot(props, 'eleventh'),
		footer: [{ name: 'Footer', id: 'footer' }],
	},
});
