/*
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited 2023.
 */
export const REQUISITION_LIST = 'requisition-list';
export const REQUISITION_LISTS_TABLE = 'requisition-lists-table';
export const REQUISITION_LISTS_STATUS = {
	PRIVATE_ORDER: 'private',
	SHARED_ORDER: 'shared',
};
export const REQUISITION_LIST_DETAILS_TABLE = 'requisition-list-details-table';
export const UPLOAD_ACTION = 'upload';
export const CSV_FILE_EXTENSION = '.csv';
export const REQUISITION_LISTS_STATUS_CODE = {
	PRIVATE: 'Y',
	SHARED: 'Z',
};
export const UPLOAD_LOGS_VIEW_TABLE = 'upload-logs-view-table';
export const UPLOAD_LOG_ERROR_TABLE = 'upload-log-error-table';

export const UPLOAD_LOG_STATUS = {
	Complete: 'Complete',
	Failed: 'Failed',
	PartiallyComplete: 'Partially Complete',
};
