import { getStyleOverrides } from '@/utils/getStyleOverrides';
import { ThemeOptions } from '@mui/material/styles';

export const Alert: ThemeOptions = {
	components: {
		MuiPaper: {
			styleOverrides: {
				root: getStyleOverrides({
					styles: {
						'&.MuiAlert-standardSuccess': {
							backgroundColor: '#EDFAEE',
							'.MuiAlert-icon, .MuiAlert-action': {
								color: '#36423D',
							},
						},
						'&.MuiAlert-standardError': {
							backgroundColor: '#FFF9F9',
							'.MuiAlert-icon, .MuiAlert-action': {
								color: '#57051B',
							},
						},
						'.MuiAlert-message': {
							color: '#000',
							fontFamily: 'Roboto',
						},
					},
				}),
			},
		},
	},
};
