import { ThemeOptions } from '@mui/material/styles';

export const Container: ThemeOptions = {
	components: {
		MuiContainer: {
			styleOverrides: {
				root: ({ theme }) => ({
					[theme.breakpoints.up('lg')]: {
						maxWidth: '1328px',
					},
				}),
			},
		},
	},
};
