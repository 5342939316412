/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { DefaultContainerLayout } from '@/data/types/ContainerLayout';

const Layout: DefaultContainerLayout = {
	name: 'ProductPageLayout',
	containerName: 'product-page',
	isStoreDefault: true,
	slots: [
		{
			id: '1',
			widgets: [
				{
					widgetName: 'breadcrumb-trail-widget',
					sequence: 0,
					name: 'BreadcrumbTrail',
				},
			],
		},
		{
			id: '2',
			widgets: [
				{
					widgetName: 'product-details-scene7-viewer-widget',
					sequence: 0,
					name: 'ProductDetailsScene7Viewer',
				},
			],
		},
		{
			id: '3',
			widgets: [
				{
					widgetName: 'product-details-name-and-price-widget',
					sequence: 0,
					name: 'ProductDetailsNameAndPrice',
				},
				{
					widgetName: 'product-details-add-to-bag-for-free-shipping-espot',
					name: 'ReactPDPFreeShipping',
					sequence: 1,
					properties: {
						emsName: 'React_PDP_Free_Shipping',
					},
				},
				{
					widgetName: 'product-details-defining-attributes-widget',
					sequence: 2,
					name: 'ProductDetailsDefiningAttributes',
				},
				{
					widgetName: 'custom-espot-with-popup-widget',
					name: 'EspotWithPopup',
					sequence: 3,
					properties: {
						emsName: 'React_PDP_SizeChartWithPopup',
					},
				},
				{
					widgetName: 'product-details-shopper-actions-widget',
					sequence: 4,
					name: 'ProductDetailsShopperActions',
				},
			],
		},
		{
			id: '4',
			widgets: [
				{
					widgetName: 'content-recommendation-widget',
					name: 'ReactPDPAccordionTitleDetails',
					sequence: 0,
					properties: {
						emsName: 'React_PDP_AccordionTitle_Details',
					},
				},
			],
		},
		{
			id: '5',
			widgets: [
				{
					widgetName: 'product-details-short-description-widget',
					name: 'ProductDetailsShortDescription',
					sequence: 0,
				},
				{
					widgetName: 'product-details-long-description-widget',
					name: 'ProductDetailsLongDescription',
					sequence: 1,
				},
				{
					widgetName: 'product-details-descriptive-attribute-widget',
					name: 'ProductDetailsDescriptiveAttributeFabricContent',
					sequence: 2,
					properties: {
						attrIdentifier: 'fabric_content',
					},
				},
				{
					widgetName: 'product-details-descriptive-attribute-widget',
					name: 'ProductDetailsDescriptiveAttributeFabricCare',
					sequence: 3,
					properties: {
						attrIdentifier: 'fabric_care',
					},
				},
			],
		},
		{
			id: '6',
			widgets: [
				{
					widgetName: 'content-recommendation-widget',
					name: 'ReactPDPAccordionTitleShippingAndReturns',
					sequence: 0,
					properties: {
						emsName: 'React_PDP_AccordionTitle_ShippingAndReturns',
					},
				},
			],
		},
		{
			id: '7',
			widgets: [
				{
					widgetName: 'content-recommendation-widget',
					name: 'ReactPDPAccordionContentShippingAndReturns',
					sequence: 0,
					properties: {
						emsName: 'React_PDP_AccordionContent_ShippingAndReturns',
					},
				},
			],
		},
		{
			id: '8',
			widgets: [
				{
					widgetName: 'content-recommendation-widget',
					name: 'ReactPDPAccordionTitleFitTips',
					sequence: 0,
					properties: {
						emsName: 'React_PDP_AccordionTitle_FitTips',
					},
				},
			],
		},
		{
			id: '9',
			widgets: [
				{
					widgetName: 'product-details-descriptive-attribute-widget',
					name: 'ProductDetailsDescriptiveAttributeFitTips',
					sequence: 0,
					properties: {
						attrIdentifier: 'Figure Type',
						attrDescription: 'Recommended for breast shape(s)',
					},
				},
			],
		},
		{
			id: '10',
			widgets: [
				{
					widgetName: 'product-details-x-sell-widget',
					sequence: 0,
					name: 'WacoalProductDetailsXSell',
				},
			],
		},
		{
			id: '11',
			widgets: [
				{
					widgetName: 'product-details-upsell-widget',
					sequence: 0,
					name: 'WacoalUpsellMerchandisingAssociation',
				},
				{
					widgetName: 'product-details-bazaar-voice-reviews-widget',
					sequence: 1,
					name: 'ProductPageBazaarVoiceReviews',
				},
				{
					widgetName: 'custom-carousel-catalog-entry-recommendation-widget',
					name: 'RecentlyViewedProductsSpot',
					sequence: 2,
					properties: {
						emsName: 'RecentlyViewedProductsSpot',
					},
				},
			],
		},
	],
};

export default Layout;
