/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { BtemptdTheme } from '@/styles/Btemptd/theme';
import { ThemeManifest } from '@/styles/manifest';
import { WacoalTheme } from '@/styles/Wacoal/theme';

export const themeManifestCustom: ThemeManifest = {
	defaultTheme: 'Wacoal',
	themes: { Wacoal: WacoalTheme, 'Wacoal Canada': WacoalTheme, Btemptd: BtemptdTheme },
};
