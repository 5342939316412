/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { productDetailLeftSX } from '@/components/content/WacoalProductPage/styles/ProductDetailLeft';
import { productDetailRightSX } from '@/components/content/WacoalProductPage/styles/ProductDetailRight';
import { ItemWrap } from '@/components/layouts/ItemWrap';
import { LayoutSlot } from '@/components/layouts/LayoutSlot';
import { AccordionSlotsDisplay } from '@/components/layouts/WacoalProductPage/AccordionSlotsDisplay';
import { layoutMainSX } from '@/components/layouts/styles/main';
import { useProductDetails } from '@/data/Content/ProductDetails';
import { S7_COMPANY } from '@/data/constants/Scene7';
import { ContentProvider } from '@/data/context/content';
import { useStoreLocatorState } from '@/data/state/useStoreLocatorState';
import { Layout } from '@/data/types/Layout';
import { Box, Container, Stack, useTheme } from '@mui/material';
import Head from 'next/head';
import { FC, useEffect, useMemo, useState } from 'react';

export const WacoalProductPage: FC<{ layout: Layout }> = ({ layout }) => {
	const {
		dimensions: { contentSpacing },
	} = useTheme();
	const { storeLocator } = useStoreLocatorState();
	const [physicalStoreName, setPhysicalStoreName] = useState<string>('');

	useEffect(() => {
		setPhysicalStoreName(storeLocator.selectedStore?.physicalStoreName ?? '');
	}, [storeLocator.selectedStore]);

	// get the id/partNumber from the first slot
	const partNumber: string = useMemo(
		() => (layout?.slots?.second ? layout?.slots?.second[0]?.id?.toString() : ''),
		[layout?.slots?.second]
	);
	// fetching partNumber from URL for out-of-stock-page, we need to add additional condition for page
	// if (typeof window !== 'undefined' && window?.location?.search) {
	// 	partNumber = window?.location?.search.slice(1);
	// }

	const productDetails = useProductDetails({
		partNumber,
		physicalStoreName,
	});
	const { product } = productDetails;

	// Need to keep above dependency as empty as we need to call this component once
	const pdpSchema = {
		'@context': 'https://schema.org',
		'@type': 'Product',
		name: product?.name,
		sku: product?.partNumber,
		description: product?.shortDescription,
		url: typeof window !== 'undefined' && window.location.href,
		image: `https://images.wacoal-america.com/is/image/${S7_COMPANY}${product?.fullImageRaw}`,
		brand: {
			'@type': 'Brand',
			name: product?.manufacturer,
		},
		offers: {
			'@type': 'Offer',
			price: product?.productPrice?.max,
			priceCurrency: product?.productPrice?.currency,
			availability: 'http://schema.org/InStock',
			priceValidUntil: '2030-12-31',
			hasMerchantReturnPolicy: {
				'@type': 'MerchantReturnPolicy',
				returnPolicyCategory: 'http://schema.org/MerchantReturnFiniteReturnWindow',
				merchantReturnDays: '45',
				returnMethod: 'http://schema.org/ReturnByMail',
				returnFees: 'http://schema.org/FreeReturn',
			},
		},
		// "aggregateRating": {
		// 	"@type": "AggregateRating",
		// 	"ratingValue": "{{4.6}}", // pulled from the average customer rating
		// 	"reviewCount": "{{1290}}" // pulled from the number of reviews
		// },
		additionalProperty: [
			{
				'@type': 'PropertyValue',
				name: 'Material',
				value: product?.attributes.find((material) => material?.identifier === 'fabric_content')
					?.values[0]?.value,
			},

			{
				'@type': 'PropertyValue',
				name: 'Size',
				value: product?.attributes.find((size) => size?.identifier === 'splash_title')?.values[0]
					?.value,
			},
		],
	};
	return (
		layout && (
			<>
				<Head>
					<script
						type="application/ld+json"
						dangerouslySetInnerHTML={{ __html: JSON.stringify(pdpSchema) }}
					/>
				</Head>
				<ContentProvider value={productDetails}>
					<LayoutSlot slot={layout.slots.header} />
					<Stack
						component="main"
						gap={contentSpacing}
						alignItems="center"
						sx={layoutMainSX(layout.slots.first?.length || 0)}
					>
						<LayoutSlot slot={layout.slots.first} ItemWrap={ItemWrap} />
						<Container>
							<Stack spacing={contentSpacing}>
								<Box>
									<Box sx={productDetailLeftSX}>
										<LayoutSlot slot={layout.slots.second} />
									</Box>
									<Box sx={productDetailRightSX}>
										{product?.partNumber ? (
											<Stack sx={{ paddingTop: { lg: '34px' } }}>
												<LayoutSlot slot={layout.slots.third} />
											</Stack>
										) : (
											''
										)}

										<AccordionSlotsDisplay
											accordionTitle={layout.slots.accordionTitle1}
											accordionContent={layout.slots.accordionContent1}
											defaultExpanded={true}
										/>
										<AccordionSlotsDisplay
											accordionTitle={layout.slots.accordionTitle2}
											accordionContent={layout.slots.accordionContent2}
										/>
										<AccordionSlotsDisplay
											accordionTitle={layout.slots.accordionTitle3}
											accordionContent={layout.slots.accordionContent3}
										/>

										<LayoutSlot slot={layout.slots.fourth} />
									</Box>
								</Box>
							</Stack>
						</Container>
						<LayoutSlot slot={layout.slots.fifth} ItemWrap={ItemWrap} />
						<LayoutSlot slot={layout.slots.footer} />
					</Stack>
				</ContentProvider>
			</>
		)
	);
};
