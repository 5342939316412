import { Layout } from '@/data/types/Layout';

export const getProductNotFoundPage = (): Layout => ({
	name: 'DoubleStack',
	slots: {
		header: [{ name: 'Header', id: 'header' }],
		first: [],
		second: [{ name: 'ProductNotFound', id: 'product-not-found' }],
		footer: [{ name: 'Footer', id: 'footer' }],
	},
});
