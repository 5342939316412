/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { DefaultContainerLayout } from '@/data/types/ContainerLayout';
const Layout: DefaultContainerLayout = {
	name: 'HomePageLayout',
	containerName: 'home-page',
	isStoreDefault: true,
	slots: [
		{
			id: '1',
			widgets: [
				{
					widgetName: 'content-recommendation-widget',
					name: 'HomePageBannerWidget',
					sequence: 0,
					properties: {
						emsName: 'ReactHomepageTop',
					},
				},
			],
		},
	],
};
export default Layout;
